<template>
  <div id="dataStatistics">
    <CSBreadcrumb />
    <div class="filter-panel" style="padding: 15px 30px;">
      <input type="text" class="filter-panel-input" placeholder="搜索姓名/手机号" v-model="queryInfo.search">
      <CSSelect style="margin-bottom: 0;">
        <select v-model="queryInfo.state">
          <option value="">签到状态不限</option>
          <option
            v-for="sign in signState"
            :key="sign.id"
            :value="sign.id"
          >{{sign.name}}</option>
        </select>
      </CSSelect>
      <button
          type="button"
          class="btn btn-primary"
          style="width: 50px; height: 30px; padding: 0; margin-bottom: 0;"
          @click="getDataStatistics()"
      >
          查询
      </button>
    </div>
    <div class="result-panel">
        <CSTable :thead-top="60">
            <template v-slot:header>
                <div class="table-header-panel">
                    <span style="margin-right: 20px;">已查看人数： {{currentDataStatistics.uvCount || 0}}</span>
                    <span style="margin-right: 20px;">已报名人数： {{currentDataStatistics.applyCount || 0}}</span>
                    <span>已签到人数： {{currentDataStatistics.registerCount || 0}}</span>
                </div>
            </template>
            <template v-slot:thead>
                <tr>
                    <th>报名时间</th>
                    <th>姓名</th>
                    <th>手机号</th>
                    <th>所属企业</th>
                    <th>所在房间</th>
                    <th>签到状态</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <tr
                    v-for="(statistics, index) in statisticsList"
                    :key="index"
                >
                    <td>{{statistics.enrollTime}}</td>
                    <td>{{statistics.userName}}</td>
                    <td>{{statistics.userPhone}}</td>
                    <td>{{statistics.company}}</td>
                    <td>{{statistics.companyAddr}}</td>
                    <td>{{signStates[statistics.state]}}</td>
                </tr>
            </template>
        </CSTable>
    </div>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import CSSelect from "@/components/common/CSSelect";
import {
  adminQueryCensusUrl
} from "@/requestUrl"
import CSTable from "@/components/common/CSTable";

export default {
  name: "DataStatistics",
  props: {
    id: Number,
    currentDataStatistics: Object,
  },
  components: {
      CSTable,
    CSSelect,
    CSBreadcrumb,
  },
  data() {
    return {
      // 返回的数据统计签到状态的处理
      signState: [
          { id: 0, name: "未签到" },
          { id: 1, name: "已签到" },
      ],
      signStates: {
          0: "未签到",
          1: "已签到",
      },
      // 查询的信息
      queryInfo: {
        regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
        activityId: "", //活动id 必填
        search: "", //搜索内容 姓名或手机号
        state: "",  //签到状态
      },
      // 数据统计列表
      statisticsList: [],
    }
  },
  created() {
    this.getDataStatistics();
    document.body.addEventListener('keydown', e => {
        if (e.key === 'Enter') {
            this.getDataStatistics();
        }
    })
  },
  methods: {
    // 查询数据统计里的信息
    async getDataStatistics() {
      let res = await this.$fly.post(adminQueryCensusUrl, {
        ...this.queryInfo,
        activityId: this.id,
      });
      if (res.code != 0) {
        return;
      }
      this.statisticsList = res.data || [];
    }
  },
}
</script>

<style lang="stylus" scoped>
.filter-panel-input
  width 230px
  height 30px
  border 1px solid #999
  border-radius 3px
  padding-left 10px
  vertical-align: middle
  margin-right: 20px
</style>
